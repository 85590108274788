 <template>
    <div>
        <div id="gamescreen">
            <div id="game-container" ref="screenContainer">
                <canvas id="background-layer" width="1170" height="658"></canvas>
                <canvas id="game-layer" width="1170" height="658"></canvas>
                <canvas id="game-ui-layer" width="1170" height="658"></canvas>
                <FullScreenButton 
                    :element="$refs.screenContainer"
                />
            </div>
            <div class="play-overlay-container">
                <transition name="fade">
                    <div v-if="!isHidden" @click="play" class="play-overlay">
                        <img :src="require('@/assets/images/cavemanPlayOverlay.png')">
                        <img class="arrow" :src="require('@/assets/images/arrowLarge.svg')">
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Caveman, {cleanUp} from "../gameFiles/cavemanIndex";
import FullScreenButton from "@/components/page_elements/FullScreenButton";
export default {
    name: 'CavemanGame',
    components: {
        FullScreenButton,
    },
    data() {
        return {
            isHidden: false,
        }
    },
    methods: {
        play() {
            this.isHidden = true;
            Caveman();
       },
    },
    beforeDestroy() {
        cleanUp();
    }
}
</script>

<style lang="scss" scoped>
    canvas { 
        position: absolute;
        top: 0;
        left: 0;
        @media (display-mode: fullscreen) {
            width: 100%;
            height: 100%;
        }
    }

    #gamescreen {
        position: relative;
        width: 1170px;
        height: 658px;
    }

    .game-container {
        position: relative;
    }

    .play-overlay-container {
        position: relative;
    }

    .play-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 25px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &.hidden {
            display: none;
        }
    } 

    .arrow {
        position: absolute;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .8s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

</style>