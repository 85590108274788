function draw() {
    playMode();
    
    getTilePosition();  
    createOnSreenList();
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
   
    drawParrallax();
    drawTiles();
    drawOnScreenItems();
    drawGameUi();
    if (!running) {
        return
    }
    requestAnimationFrame(draw);
}

function playMode() {
        playActionKeys();
        movePlayerX();
        movePlayerY();
        findPlayerState();
        animate(playerData);
        objectCheck();
        playSetScrollValues();
}

// Sets state based on conditions and loads new detail if changed

function findPlayerState(){
    playerData.invulnerableDelay = playerData.invulnerableDelay > 0 ? playerData.invulnerableDelay -= 1 : playerData.invulnerableDelay = 0;
    if (playerData.hitDelay > 0) {
        if (playerData.health < 1) {
            playerReset();
            playerData.state = 'stand'
        } else {
            playerData.state = 'hit';
            playerData.hitDelay -= 1;
        }
    } else {
        if (playerData.inAir > 0) {
            if (playerData.attack.counter > 0) {
                playerData.state = 'airAttack';
                playerData.attack.counter -= 1;
            } else {
                if (playerData.sY < 0) {
                    playerData.state = 'up';
                }  else {
                    playerData.state = 'down';
                }
            }
        } else {
            if (playerData.attack.counter > 0) {
                playerData.state = 'groundAttack';
                    playerData.attack.counter -= 1;
            } else {
                if (Math.abs(playerData.sX) > 0) {
                    playerData.state = 'run';
                } else {
                    playerData.state = 'stand';
                }
            }
        }
    }    
    if (playerData.state != playerData.frame.name) {
        playerData.frame = playerFrameModes[playerData.state];
        playerData.drawing.image = playerData.frame.baseImage;
        if (playerFrameModes[playerData.state].attack) {
            playerData.attack = playerFrameModes[playerData.state].attackData;
        }
    }
}

function playerReset() {
    playerData.xPos = playerData.resetPosition.xPos;
    playerData.yPos = playerData.resetPosition.yPos;
    playerData.health = 10;
    playerData.hitDelay = 0;
}

function checkFloor(obs) {
    obs.solidTile = findTile2(obs.xPos - obs.width, obs.yPos, obs.xPos + obs.width, obs.yPos);
    if (obs.solidTile != 0) {
        obs.yPos = Math.floor(obs.yPos);
        obs.sY = 0;
        obs.inAir = 0;
        obs.bottomYvalue = obs.yPos;
        if(obs.solidTile === 2 && !obs.invulnerableDelay) {
            obs.hitDelay = 20;
            obs.invulnerableDelay = 60;
            obs.attack.counter = 0;
            obs.health -= 1;
            redrawGameUI = true;
        }
    } else {
        obs.inAir += 1;
    }  
}  

function checkCeiling(obs) {
    obs.solidTile = findTile2(obs.xPos - obs.width, obs.yPos - obs.height, obs.xPos + obs.width, obs.yPos - obs.height);
    if (obs.solidTile === 1) {
        obs.yPos = Math.ceil(obs.yPos - obs.height) + obs.height;
        obs.sY = 0;
    } else {
        obs.inAir += 1;
    }
}

function checkWalls(obs) {
    obs.solidTile = findTile2(obs.xPos + (obs.width * obs.direction), obs.yPos - 0.1, obs.xPos + (obs.width * obs.direction), obs.yPos - obs.height);
    if(obs.solidTile === 0) obs.solidTile = findTile(obs.xPos + (obs.width * obs.direction), obs.yPos - obs.height / 2);
    if (obs.solidTile === 1) {
        if (obs.sX < 0) {
            obs.xPos = Math.ceil(obs.xPos - obs.width) + obs.width;
        } else {
            obs.xPos = (Math.floor(obs.xPos + obs.width) - obs.width) - 0.001;
        } 
    }
}

// Sets centre point of screen 

function playSetScrollValues() {
    scrollX = ((playerData.xPos - windowSizeX / 2) + (scrollX * 2)) / 3;
    if(scrollX < 0) scrollX = 0;
    if(scrollX > mapWidth - windowSizeX) scrollX = mapWidth - windowSizeX;
    let tempScrollY = ((playerData.yPos - windowSizeY / 1.4) + (scrollY * 9)) / 10;
    if (playerData.drawing.yPos - (playerData.height * tileSize) < 0) playerData.bottomYvalue = 0;
    if (playerData.inAir === 0 || playerData.yPos > playerData.bottomYvalue) scrollY = tempScrollY;
    if(scrollY > mapHeight - windowSizeY) scrollY = mapHeight - windowSizeY;
    if(scrollY < 0) scrollY = 0;
    
}

function movePlayerX() {
    playerData.sX = Math.abs(playerData.sX) < 0.02 ? 0: playerData.sX *= 0.75;
    playerData.xPos += playerData.sX;
    checkWalls(playerData);
    if (playerData.solidTile === 1) playerData.sX = 0;
} 

function movePlayerY() {
    let gravity = playerData.sY < 0 ? 0.02 : 0.015;
    playerData.sY = (playerData.sY + gravity) * 0.96;
    playerData.yPos += playerData.sY;
    if (playerData.sY > 0) {
        checkFloor(playerData);
    } else {
        checkCeiling(playerData);
    }
}

// Checks objects for deletion, if on screen and if touching player

function objectCheck(){
    objectList = objectList.filter(function(item) {
        return item.delete === 0;
    })
    checkListOnScreen(objectList);
    objectList.forEach(function(item) {
        if (item.onScreen === true && item.active === true) {
            if (collisionDetect(playerData,item) === true) {
                objectAction(item);
            }
        }
    })
}

// Runs object actions if touching player

function objectAction(item) {
    if (item.typeName === 'vent') {
        playerData.sY = -0.5;
    } else if (item.typeName === 'log') {
        playerData.resetPosition.xPos = item.xPos
        playerData.resetPosition.yPos = item.yPos
        item.drawing.image += 1;
        item.frame.baseImage += 1;
        item.frame.render.y = [0.6];
    } else {
        playerData.collect[item.typeName] += item.valueChange;
    }
    if (item.afterAction === 'delete') item.delete = 1;
    if (item.afterAction === 'deactive') item.active = false;
}

// Checks if items 1 and 2 and touching

function collisionDetect(item1, item2) {
    if (item1.xPos - item1.width < item2.xPos + item2.width &&
        item1.xPos + item1.width > item2.xPos - item2.width &&
        (item1.yPos - item1.height) < item2.yPos  &&
        item1.yPos  > (item2.yPos - item2.height))
    {
        return true
    } else {
        return false
    }
}

// finds tile relative to inputs

function findTile(x, y) {
    let tileIndex = (Math.floor(y) * mapWidth) + Math.floor(x);
    return mapSolidList[tileIndex];
}

function findTile2(x, y, x2, y2) {
    let returnedTile = findTile(x, y);
    if (returnedTile === 0) {
        returnedTile = findTile(x2, y2);
    }
    return returnedTile;
}

// Calculates the x position of each tile on the canvas

function getTilePosition() {
    tileList.forEach(function(tile) {
        checkTileChange(tile); //Check if tile has moved offscreen and recalcules tile if so
        tile.xPos = Math.floor((tile.xTilePos - scrollX) * tileSize); // Gets x position on canvas 
        tile.yPos = Math.floor((tile.yTilePos - scrollY) * tileSize); // Gets y position on canvas 
        tile.image =  mapList[tile.tileNum];
    })
}

// Checks if tile is outside of the screen, moves tile to opposite side and calculates image

function checkTileChange(tile) {
    if (tile.xTilePos - scrollX > windowSizeX) getTileNum(tile, (-1 - windowSizeX), 0);
    if (tile.xTilePos - scrollX < -1 ) getTileNum(tile, (windowSizeX + 1), 0);
    if (tile.yTilePos - scrollY > windowSizeY) getTileNum(tile, 0, (-1 - windowSizeY));
    if (tile.yTilePos - scrollY < -1) getTileNum(tile, 0, (windowSizeY + 1));
}

// Changes x and y tile coordinates for and calculates tile image using x and y positions

function getTileNum(input,xChange, yChange) {
    input.xTilePos += xChange;
    input.yTilePos += yChange;
    input.tileNum = (input.yTilePos * mapWidth) + (input.xTilePos);
    input.image = mapList[input.tileNum];
}

// Draws all tiles on screen from tileList values

function drawTiles() {
    ctx.save();
    tileList.forEach(function(tile) {  
        if (tile.image > 0) drawTile(images[0], tile.xPos, tile.yPos, tile.image);
    });
    ctx.restore();
} 


function drawTile(image, x, y, tile) {
    let tS = tileSize;
    ctx.drawImage(image, (tile % 5) * tS, Math.floor(tile / 5) * tS, tS, tS, x, y, tS, tS);
}

// Gets all tiles starting image based on position

function getInitalTile() {
    tileList.forEach(function(tile) { 
        getTileNum(tile,0, 0);
    })
}

function animate(item) {
    item.frame.counter += 1;
    let fs = item.frame.frameList;
    let currentFrame = fs[Math.floor((item.frame.counter / item.frame.speed) % fs.length)]; //Calculates frame of frame string
    item.drawing.image = item.frame.baseImage + currentFrame;
}

//Adds folder to image URLs

function completeUrl(urlList, folder = '') {
    if (urlList === null) return;
    return urlList.map(URL => require('@/assets/images/caveman/' + folder + URL));
}

function loadImages() {
    let imageURL = [...completeUrl(miscImageURL), ...completeUrl(playerImageURL, 'NewPlayer/'), ...completeUrl(obsImageURL, 'obs/')];
    imageURL.forEach(function(src) {
        const image = new Image();
        image.src = src;
        image.onload = function() { 
            imageCount += 1;
            if(imageCount === imageURL.length){ 
                levelAssestLoaded(); 
            }
        }
        images.push(image); 

    });
}

function levelAssestLoaded() {
    loadObjects(objectStartList);
    drawBackground();
    drawGameUi();
    requestAnimationFrame(draw); 
}

// Compiles list of items that are onscreen and will be drawn in this frame

function createOnSreenList() {
    onScreenList = [];
    addListOnScreen(objectList);
    checkOnScreen(playerData);
    if (playerData.onScreen === true) {
        refreshDrawingData(playerData);
        onScreenList.push(playerData);
    }
}

// Checks if object is within screen based on window size

function checkOnScreen(item) {
    item.onScreen = false;
    if(Math.abs(item.xPos - (scrollX + (windowSizeX / 2))) < (windowSizeX / 2) + 2 ) {
        if(Math.abs(item.yPos - (scrollY + (windowSizeY / 2))) < (windowSizeY / 2) + 2 ) {
            item.onScreen = true;
        }
    }
}


// Checks each item on a list to see if it's onscreen

function checkListOnScreen(list) {
    list.forEach(function(item) {  
        checkOnScreen(item);
    });
}

// Updates data for drawing object on screen

function refreshDrawingData(item) {
    item.drawing.xPos = Math.floor((item.xPos - scrollX) * tileSize);
    item.drawing.yPos = Math.floor((item.yPos - scrollY) * tileSize);
    item.drawing.direction = item.direction;
    item.drawing.render.x = item.frame.render.x[item.drawing.image - item.frame.baseImage];
    item.drawing.render.y = item.frame.render.y[item.drawing.image - item.frame.baseImage];
}

// Updates drawing data for items on a list on screen and adds to onscreenlist for rendering

function addListOnScreen(list){
    list.forEach(function(item) {
        if (item.onScreen === true) {
            refreshDrawingData(item);
            onScreenList.push(item);
        }
    })
}

// Draws all items in onsreen list

function drawOnScreenItems() {
    ctx.save();
    onScreenList.forEach(function(item) {
        drawImage(images[item.drawing.image], item.drawing.xPos, item.drawing.yPos, 1.5, item.drawing.direction, item.drawing.render)
    })
    ctx.restore();
}

function drawImage(image, x, y, scale = 1, direction, render, definedCtx = ctx) {
    definedCtx.setTransform(scale * direction, 0, 0, scale, x, y); 
    definedCtx.drawImage(image, Math.floor(-image.width * render.x), Math.floor(-image.height * render.y));
}

function makeTile(xTile,yTile) {
    return {
        image: 0,
        xTilePos: xTile,
        yTilePos: yTile,
        xPos: 0,
        yPos: 0,
        tileNum: 0,
    }
}

// Create tile with x and y values relative to starting scroll x and y position

function createTileList() {
    let xStart = scrollX + Math.floor(windowSizeX / -2);
    let yStart = scrollY + Math.floor(windowSizeY / -2);
    for (let i = 0; i < windowSizeY + 1; i++) {
        for (let i2 = 0; i2 < windowSizeX + 1; i2++) {
            tileList.push(makeTile(xStart, yStart));
            xStart += 1;
        }
        xStart = scrollX;
        yStart += 1;
    }
}


function createMap() {
    mapList = [];
    mapSolidList = [];
    mapList = decodeLevel(mapFullList);
    mapList.forEach(function(tile) {
        mapSolidList.push(solidTileList[tile])
    })
}

function decodeLevel(levelData) {
    let mapUpload = [];
    let tileRun = 0;
    let currentTile = 0;
    currentTile = levelData[tileRun];
    while (tileRun < levelData.length) {
        currentTile = levelData[tileRun];
        for (let i = 0; i < levelData[tileRun + 1]; i++) {
            mapUpload.push(currentTile);
        }
    tileRun += 2;
    }
    return mapUpload;
}

function playActionKeys() {
    if (playerData.hitDelay === 0) {  
        if (keyup) {
            if (playerData.inAir === 0 && playerData.attack.counter === 0) {
                playerData.sY = -0.37;
            }
        } 
        if (playerData.state != 'groundAttack') {
            if (keyleft) {
                playerData.sX -= 0.04;
                playerData.direction = -1;
            }   
            if (keyright) {
                playerData.sX += 0.04; 
                playerData.direction = 1;
            } 
        }
        if (keyD) {
            if (playerData.attack.counter === 0) {
                playerData.attack.counter = 24
            }
        }
    }
}

function keyHandler(event) {
    if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(event.code) > -1) {
        event.preventDefault();
    }
    
    if (event.key === "ArrowUp") keyup = event.type === 'keydown';
    if (event.key === "ArrowRight") keyright = event.type === 'keydown';
    if (event.key === "ArrowLeft") keyleft = event.type === 'keydown'; 
    if (event.key === "d") keyD = event.type === 'keydown'; 
}

function addObjectData() {
    levelImport.objects.forEach((object) => {
        objectTypeData.push(objectDetails[object]);
        obsImageURL.push(...objectDetails[object].images);
    })
    setStartImages();
}

function makeObject(input) {
    return {
        onScreen: false,
        active: true,
        onePerLevel: objectTypeData[input.type].onePerLevel,
        delete: 0,
        typeName: objectTypeData[input.type].name,
        type: input.type,
        xPos: input.xPos,
        yPos: input.yPos,
        direction: 1,
        valueChange: objectTypeData[input.type].valueChange,
        afterAction: objectTypeData[input.type].afterAction,
        width: (objectTypeData[input.type].width / tileSize) / 2,
        height: (objectTypeData[input.type].height / tileSize),
        frame: {
            baseImage: input.drawing.image,
            render: {   
                x: [0.5],
                y: [1],
            },
        },
        drawing: {
            image: input.drawing.image,
            xPos: null,
            yPos: null,
            direction: null,
            render: {
                x: null,
                y: null,
            },
        },
    }
}

function setStartImages() {
    imgStart = {
        player: miscImageURL.length,
        obs: miscImageURL.length + playerImageURL.length,
    };
}

function loadObjects(elist) {
    objectList = [];
    let tempObjectList = [];
    for (let i = 0; i < elist.length;) {
        tempObjectList.push({
            type: elist[i], 
            xPos: elist[i + 1],
            yPos: elist[i + 2], 
            drawing: {
                image: elist[i + 3]
            } 
        })
        i += 4;
    }
    tempObjectList.forEach(item => objectList.push(makeObject(item)))
}

function drawGameUi() {
    if (!redrawGameUI) return;
    gameUiCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    let render = {
        x: [0.5],
        y: [1],
    }
    gameUiCtx.save();
    drawImage(images[6], 140, 100, 0.75, 1, render, gameUiCtx);
    gameUiCtx.restore();
    gameUiCtx.save();
    let barColour = playerData.health > 3 ? '#00A800' : '#CC0000';
    gameUiCtx.fillStyle = barColour;
    gameUiCtx.fillRect(10, 20, 25 * playerData.health, 30);
    gameUiCtx.restore();
    redrawGameUI = false;
}

function drawBackground() {
    const canvas = document.getElementById('background-layer');
    const ctx = canvas.getContext('2d', { alpha: false });
    let render = {
        x: [0.5],
        y: [1],
    }
    drawImage(images[1], 640, 720, 1, 1, render, ctx);
}

function drawParrallax () {
    ctx.save();
    parralaxList.forEach(function(layer) {
        for (let i = 0; i < layer.repeated; i++ ) {
            let pXpos = (i * layer.width) - ((scrollX * tileSize) * layer.speed);
            let totalwidth = (layer.width * layer.repeated);
            let paraxPos = Math.floor((((pXpos % totalwidth ) + totalwidth ) % totalwidth) - layer.width);
            let xStartCut = paraxPos < 0 ? paraxPos * -1  : 0; // If the section starts off left of screen then cut the background for where it would show at x = 0
            let xEndCut = paraxPos + layer.width > canvasWidth ? canvasWidth - paraxPos : layer.width; // If the section end off right of screen then cut the background to where it would show at screen end
            if (paraxPos < 0) paraxPos = 0; // If section starts before x = 0 set to draw from 0
            ctx.drawImage(images[layer.image], xStartCut, 0, xEndCut, layer.height, paraxPos, canvasHeight - layer.height, xEndCut, layer.height)
        }
    })
    ctx.restore();
}

function setUp () {
    if (running) {
        console.log('An instance of this programme is already running')
        return;
    }
    running = true;
    playerData.xPos = 4;
    playerData.yPos = 19;
    document.addEventListener('keydown', keyHandler, false);
    document.addEventListener('keyup', keyHandler, false);canvas = document.getElementById('game-layer');
    gameUiCanvas = document.getElementById('game-ui-layer');
    ctx = canvas.getContext('2d');
    gameUiCtx = gameUiCanvas.getContext('2d');
    addObjectData();
    createTileList();
    createMap();
    getInitalTile();
    loadImages();
}

function cleanUp() {
    running = false;
    imageCount = 0;
    document.removeEventListener('keydown', keyHandler, false);
    document.removeEventListener('keyup', keyHandler, false);
}

let running = false;
let ctx;
let canvas;
let gameUiCtx;
let gameUiCanvas;
let mapFullList = [0,574,2,1,1,13,0,136,2,1,1,13,0,136,2,1,1,13,0,136,2,1,1,13,0,136,2,1,1,13,0,131,11,1,0,1,11,1,0,2,2,1,1,13,0,
    113,8,1,7,6,23,1,21,5,25,1,7,4,10,1,0,1,10,1,7,2,16,1,1,13,0,99,8,1,7,1,9,1,0,6,8,1,9,1,0,3,5,1,4,6,22,1,20,5,24,1,4,4,6,1,0,
    1,5,1,4,3,1,13,0,98,8,1,18,1,4,1,6,1,0,6,5,1,6,1,0,3,2,1,1,6,3,1,0,5,2,1,1,4,3,1,0,1,2,1,1,16,0,72,8,1,7,2,23,1,21,8,25,1,9,1,
    0,11,8,1,18,1,4,1,1,1,3,1,0,2,8,1,9,1,0,2,2,1,17,1,9,1,0,2,2,1,1,6,3,1,0,5,2,1,1,4,3,1,0,1,2,1,1,16,0,56,8,1,9,1,0,8,11,1,0,5,
    5,1,4,2,22,1,20,8,24,1,6,1,0,2,8,1,7,1,23,1,21,4,25,1,7,1,18,1,4,1,1,2,3,1,0,2,5,1,6,1,0,2,2,1,4,1,6,1,0,2,2,1,1,6,3,1,0,5,2,
    1,1,4,3,1,0,1,2,1,1,16,0,55,8,1,18,1,6,1,0,7,8,1,10,1,7,5,16,1,1,2,3,1,0,8,2,1,3,1,0,2,5,1,4,1,22,1,20,4,24,1,4,2,1,3,3,1,15,
    2,2,1,3,1,15,2,2,1,3,1,0,16,2,1,1,4,3,1,0,1,2,1,1,16,0,21,8,1,7,2,9,1,0,23,8,1,9,1,0,5,5,1,4,1,17,1,9,1,0,6,5,1,4,7,1,2,3,1,0,
    8,2,1,3,1,0,2,2,1,1,1,3,1,0,4,2,1,1,6,14,2,1,2,14,2,1,1,3,1,0,16,2,1,1,4,3,1,0,1,2,1,1,16,0,20,8,1,18,1,4,2,6,1,0,3,8,1,7,5,9,
    1,0,6,8,1,7,2,9,1,0,3,5,1,6,1,0,2,11,1,0,2,2,1,1,1,4,1,6,1,0,5,8,1,16,1,1,9,3,1,0,19,2,1,1,13,17,1,7,1,9,1,0,3,8,1,7,2,23,1,
    21,3,25,1,9,1,0,2,2,1,1,4,3,1,0,1,2,1,1,16,0,17,11,1,0,2,5,1,4,1,1,2,3,1,0,3,5,1,4,5,6,1,0,4,8,1,7,1,18,1,4,2,6,1,0,3,2,1,17,
    1,7,2,10,1,7,2,16,1,1,2,3,1,11,1,0,4,5,1,4,1,1,5,3,1,0,36,1,1,4,2,6,1,0,3,5,1,4,2,22,1,20,3,24,1,6,1,0,19,2,1,1,6,0,12,7,5,10,
    1,7,2,16,1,1,3,17,1,7,3,16,1,1,5,17,1,7,4,18,1,4,2,1,2,3,1,15,3,2,1,4,7,1,2,17,1,10,1,7,4,16,1,1,6,17,1,7,13,9,1,0,2,8,1,9,1,
    0,24,8,1,16,1,1,2,3,1,0,3,2,1,17,1,9,1,0,10,8,1,7,7,16,1,1,6,0,12,4,9,1,3,4,5,1,5,4,6,1,5,14,3,1,10,4,7,1,6,4,14,6,1,15,2,5,1,
    19,1,7,9,9,1,0,2,8,1,7,1,9,1,0,9,5,1,4,1,1,2,3,1,0,3,2,1,4,1,6,1,0,10,5,1,4,8,1,18,12,73,1,1,14,2,1,1,4,10,6,1,15,2,5,1,4,1,6,
    1,0,6,11,1,0,2,2,1,1,3,3,1,0,3,2,1,1,1,17,1,7,10,16,1,1,26,13,73,12,14,1,1,14,2,1,2,17,1,7,6,10,1,7,2,16,1,1,3,3,1,15,3,2,1,1,
    1,4,12,1,26,13,87,1,5,4,11,1,4,14,3,1,3,12,37,13,87,12,26,13,187];
let objectStartList = [ 1,120.5,15,32, 1,120.5,17,32, 1,120.5,11,32, 1,120.5,12,32, 1,120.5,13,32, 1,120.5,14,32, 1,120.5,16,32,
    1,120.5,10,32, 2,92.5,12,33, 2,93.5,12,33, 2,93.5,11,33, 2,92.5,11,33, 2,122.5,8,34, 2,123.5,8,34, 2,60.5,18,34,2,61.5,18,
    34,2,62.5,18,34, 2,63.5,18,34];
let levelData = {
    startX: 3,
    startY: 19,
    mapHorizontal: 150,
    mapVertical: 25,
};
const levelImport = {
    objects: ['cash','red','blue'],
}
let redrawGameUI = true;
const canvasWidth = 1170;
const canvasHeight = 658;
const tileSize = 128;
const windowSizeX = Math.ceil(canvasWidth / tileSize);
const windowSizeY = Math.ceil(canvasHeight / tileSize);
let mapWidth = levelData.mapHorizontal;
let mapHeight = levelData.mapVertical;
let scrollX = levelData.startX - windowSizeX / 2;
let scrollY = levelData.startY - windowSizeY / 2;
let miscImageURL = ["128VI.png", "background720.png", "background5.png","deleteCross.svg", "numbers.png", "newText.png", "stoneUI2.png"];
const playerImageURL = ["stand.svg", "run1.svg", "run2.svg", "run3.svg", "run4.svg", "run5.svg", "run6.svg", "run7.svg",
  "run8.svg", "down.svg", "up.svg", "swingcycle1.svg","swingcycle2.svg","swingcycle3.svg","swingcycle4.svg","swingcycle5.svg",
  "swingcycle6.svg", "hit1.svg", "hit2.svg","airSwing1.svg","airSwing2.svg","airSwing3.svg","airSwing4.svg","airSwing5.svg",
  "airSwing6.svg"];
let obsImageURL = [];
const images = []; 
let imgStart = {
    player: miscImageURL.length,
    obs: miscImageURL.length + playerImageURL.length,
};
let objectTypeData = [];
let parralaxList = [
    {image: 2, height: 508, width: 874, repeated: 3, speed: 0.2},
];
let mapList = [];
let tileList = [];
const solidTileList = [0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 1, 1, 2, 0, 1, 1, 1, 1, 1, 0, 1, 0, 1, 0];
let mapSolidList = [];
let imageCount = 0;
let onScreenList = [];
let objectList = [];
let keyup = false;
let keyright = false;
let keyleft = false;
let keyD = false;
const playerFrameModes = {
    stand: {
        name: 'stand',
        counter: 0,
        speed: 12,
        baseImage: imgStart.player,
        frameList: [0],
        render: {
            x:[0.5],
            y:[1],
        }, 
    },
    run: {
        name: 'run',
        counter: 0,
        speed: 6,
        baseImage: imgStart.player + 1,
        frameList: [0, 1, 2, 3, 4, 5, 6, 7],
        render: {
            x: [0.4,0.35,0.35,0.4,0.5,0.35,0.35,0.2],
            y: [1,1,1,1.2,1,1,1,0.85],
        },      
    },
    up: {
        name: 'up',
        counter: 0,
        speed: 1,
        baseImage: imgStart.player + 10,
        frameList: [0],
        render: {
            x:[0.5],
            y:[1],
        }, 
    },
    down: {
        name: 'down',
        counter: 0,
        speed: 1,
        baseImage: imgStart.player + 9,
        frameList: [0],
        render: {
            x:[0.5],
            y:[1],
        }, 
    },
    groundAttack: {
        name: 'groundAttack',
        counter: 0,
        speed: 2,
        baseImage: imgStart.player + 11,
        frameList: [0,0,0,0,1,1,2,3,4,5,5,5,5],
        render: {
            x:[0.6,0.45,0.40,0.3,0.25,0.25],
            y:[1,1,1,1,1,1],
        },
        attackMode: true,
        attackData: { 
            counter: 0,
            xFrame: [-50,0,40,60,80,80],
            yFrame: [120,140,120,90,60,50],
            attacking: [1, 1, 1, 1, 1, 1],
            radius: 10,
            type: 1,
        }
    },
    airAttack: {
        name: 'airAttak',
        counter: 0,
        speed: 2,
        baseImage: imgStart.player + 19,
        frameList: [0,0,0,1,1,2,3,4,5,5,5,5],
        render: {
            x:[0.6,0.45,0.4,0.3,0.25,0.2],
            y:[0.95,0.95,0.95,0.95,0.95,0.95],
        },
        attackMode: true,
        attackData: {
            counter: 0,
            x: [-40,0,40,60,70,70],
            y: [120,130,120,90,60,40],
            attacking: [1, 1, 1, 1, 1, 1],
            radius: 10,
            type: 1,
        }
    },
    hit: {
        name: 'hit',
        counter: 0,
        speed: 5,
        baseImage: imgStart.player + 17,
        frameList: [0, 1, 1, 1],
        render: {
            x:[0.55, 0.55],
            y:[1, 1],
        }, 
    },
}
let playerData = {
    onScreen: false,
    typeName: 'Player',
    xPos: levelData.startX,
    yPos: levelData.startY,
    collect: {
        red: 0,
        blue: 0,
        cash: 0,
    },
    resetPosition: {
        xPos: levelData.startX,
        yPos: levelData.startY,
    },
    bottomYvalue: 0,
    sY: 0,
    sX: 0,
    direction: 1,
    height: 180 / tileSize,
    width: (70 / tileSize) / 2,
    inAir: 0,
    health: 10,
    hitDelay: 0,
    invulnerableDelay: 0,
    solidTile: 0,
    state: 'down',
    attack: {
        counter: 0,
        xFrame: [-50,0,40,60,80,80],
        yFrame: [120,140,120,90,60,50],
        attacking: [1, 1, 1, 1, 1, 1],
        radius: 10,
        type: 1,
    },
    frame: {
        name: 'down',
        counter: 0,
        speed: 1,
        baseImage: imgStart.player + 13,
        frameList: [0],
        render: {
            x: [0.5],
            y: [1],
        },
    },
    drawing: {
        image: imgStart.player + 13,
        xPos: 0,
        yPos: 0,
        direction: 0,
        render: {
            x: 0,
            y: 0,
        },

    },
}

const objectDetails = {
    cash: {
        name: 'cash',
        images: ["diamond.svg"],
        height: 64,
        width: 62,
        valueChange: 1,
        objectType: 'collect',
        afterAction: 'delete',
        onePerLevel: false,
    },
    red: {
        name: 'red',
        images: ["emerald.svg"],
        imageNum: 0,
        height: 46,
        width: 40,
        valueChange: 1,
        objectType: 'collect',
        afterAction: 'delete',
        onePerLevel: false
    },
    blue: {
        name: 'blue',
        images: ["ruby.svg"],
        imageNum: 0,
        height: 46,
        width: 40,
        valueChange: 1,
        objectType: 'collect',
        afterAction: 'delete',
        onePerLevel: false,
    },
}

export default setUp;
export {cleanUp};
