<template>
<div>
    <ProjectPageTemplate
        projectTitle="CAVEMAN"
        :imageSource="require('@/assets/images/projectLinks/cavemanLink.png')"
        shortDescription="NOT SUITABLE FOR MOBILE - Click to start. Press left and right keys to move, Up to jump and D to swing club. A tile based platform caveman game. Using a HTML canvas this is a program written in JavaScript."
        :longDescription="description"
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
      <div id="content-container">
        <div class="content">
          <CavemanGame></CavemanGame>
        </div>
        <div class="screen-small">
          <UnableToDisplay
            :imageSource="require('@/assets/images/shadowExpand.svg')"
            text="SCREEN SIZE TOO SMALL"
          />
        </div>
      </div>
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import CavemanGame from '../components/CavemanGame.vue';
import UnableToDisplay from "@/components/page_elements/UnableToDisplay.vue"
import techLinks from '@/data/techLinks.js'
export default {
    name: 'Caveman',
    components: {
        ProjectPageTemplate,
        CavemanGame,
        UnableToDisplay,
    },
    data() {
        return {
            description: `Version history can be viewed <a href="/CavemanVersions">here</a>.<br>
                        <br>This was made as demonstration of a JavaScript platform editor/engine that I am hoping to release at some point as open source. It was a useful exercise in furthering my skills in animation and rendering images on a HTML canvas, in addition to learning how to use key inputs within a JS project and on a web page. Arrays are used to store the level layout and tiles are drawn on screen based on the scrolling values.<br>
                        <br>The tiles and background are drawn using a mixture of Scratch and Gimp software, with the player being SVG images drawn in scratch. I also used a separate JS program I had made to find the correct render points for the animation.<br>
                        <br>As I add further features to the editor this project will be updated to showcase these elements. Currently working to add enemies and projectiles to the project.`,
            techLinksData: [
              techLinks.html, 
              techLinks.javascript,
              techLinks.gimp,
              techLinks.scratch,
            ],
        }
      },
  }
</script>

<style lang="scss" scoped>
$content-width: 1190px;

#content-container {
  .screen-small {
    display: none;
  }

  @media only screen and (max-width: ($content-width + 1)) {
    width: 100%;
    .screen-small {
      display: flex;
    }
    .content {
      display: none;
    }
  }
}

</style>